<template>
  <div class='maturity-stages'
    :class='{ "is-chart": mode === "chart" }'>
    <svg viewBox='0 0 300 148'>
      <g>
        <g class='brk'
          clip-path='url(#b5f41dba)'
          :class='{
            "is-widget": mode === "widget",
            "is-active": stage === "newcomer"
          }'
          @click='$emit("select", "newcomer")'>
          <rect
            x='0'
            y='0'
            width='56.8'
            height='140'
            fill='url(#32e6cb20)'/>
          <g class='ltx'
            font-size='6'>
            <text
              x='3.496'
              y='129.56'>
              LEVEL 1
            </text>
            <text
              x='3.496'
              y='135.962'>
              NEWCOMER
            </text>
          </g>
        </g>
        <g class='brk'
          clip-path='url(#c5bee6d7)'
          :class='{
            "is-widget": mode === "widget",
            "is-active": stage === "explorer"
          }'
          @click='$emit("select", "explorer")'>
          <rect
            x='60.8'
            y='0'
            width='56.8'
            height='140'
            fill='url(#6a6b2fe7)'/>
          <g class='ltx'
            font-size='6'>
            <text
              x='64.274'
              y='129.56'>
              LEVEL 2
            </text>
            <text
              x='64.274'
              y='135.962'>
              EXPLORER
            </text>
          </g>
        </g>
        <g class='brk'
          clip-path='url(#90105f8e)'
          :class='{
            "is-widget": mode === "widget",
            "is-active": stage === "challenger"
          }'
          @click='$emit("select", "challenger")'>
          <rect
            x='121.6'
            y='0'
            width='56.8'
            height='140'
            fill='url(#d77275f6)'/>
          <g class='ltx'
            font-size='6'>
            <text
              x='125.282'
              y='129.56'>
              LEVEL 3
            </text>
            <text
              x='125.282'
              y='135.962'>
              CHALLENGER
            </text>
          </g>
        </g>
        <g class='brk'
          clip-path='url(#1f34d319)'
          :class='{
            "is-widget": mode === "widget",
            "is-active": stage === "practitioner"
          }'
          @click='$emit("select", "practitioner")'>
          <rect
            x='182.4'
            y='0'
            width='56.8'
            height='140'
            fill='url(#7bf28ccf)'/>
          <g class='ltx'
            font-size='6'>
            <text
              x='185.896'
              y='129.56'>
              LEVEL 4
            </text>
            <text
              x='185.896'
              y='135.962'>
              PRACTITIONER
            </text>
          </g>
        </g>
        <g class='brk'
          clip-path='url(#915d0746)'
          :class='{
            "is-widget": mode === "widget",
            "is-active": stage === "champion"
          }'
          @click='$emit("select", "champion")'>
          <rect
            x='243.2'
            y='0'
            width='56.8'
            height='140'
            fill='url(#b5a50443)'/>
          <g class='ltx'
            font-size='6'>
            <text
              x='246.882'
              y='129.56'>
              LEVEL 5
            </text>
            <text
              x='246.882'
              y='135.962'>
              CHAMPION
            </text>
          </g>
        </g>
        <g>
          <path class='sop'
            d='M56.8 146a2 2 0 00-2-2h-52.8a2 2 0 000 4h52.8a2 2 0 002-2z'
            :class='{ "is-active": stage === "newcomer" }'/>
          <path class='sop'
            d='M117.6 146a2 2 0 00-2-2h-52.8a2 2 0 000 4h52.8a2 2 0 002-2z'
            :class='{ "is-active": stage === "explorer" }'/>
          <path class='sop'
            d='M178.4 146a2 2 0 00-2-2h-52.8a2 2 0 000 4h52.8a2 2 0 002-2z'
            :class='{ "is-active": stage === "challenger" }'/>
          <path class='sop'
            d='M239.2 146a2 2 0 00-2-2h-52.8a2 2 0 000 4h52.8a2 2 0 002-2z'
            :class='{ "is-active": stage === "practitioner" }'/>
          <path class='sop'
            d='M300 146a2 2 0 00-2-2h-52.8a2 2 0 000 4h52.8a2 2 0 002-2z'
            :class='{ "is-active": stage === "champion" }'/>
        </g>
      </g>
      <defs>
        <clipPath id='b5f41dba'>
          <path d='M56.8 68c0-2.208-1.792-4-4-4h-48.8c-2.208 0-4 1.792-4 4v68c0 2.208 1.792 4 4 4h48.8c2.208 0 4-1.792 4-4V68z'/>
        </clipPath>
        <clipPath id='c5bee6d7'>
          <path d='M117.6 52c0-2.208-1.792-4-4-4h-48.8c-2.208 0-4 1.792-4 4v84c0 2.208 1.792 4 4 4h48.8c2.208 0 4-1.792 4-4V52z'/>
        </clipPath>
        <clipPath id='90105f8e'>
          <path d='M178.4 36c0-2.208-1.792-4-4-4h-48.8c-2.208 0-4 1.792-4 4v100c0 2.208 1.792 4 4 4h48.8c2.208 0 4-1.792 4-4V36z'/>
        </clipPath>
        <clipPath id='1f34d319'>
          <path d='M239.2 20c0-2.208-1.792-4-4-4h-48.8c-2.208 0-4 1.792-4 4v116c0 2.208 1.792 4 4 4h48.8c2.208 0 4-1.792 4-4V20z'/>
        </clipPath>
        <clipPath id='915d0746'>
          <path d='M300 4c0-2.208-1.792-4-4-4h-48.8c-2.208 0-4 1.792-4 4v132c0 2.208 1.792 4 4 4h48.8c2.208 0 4-1.792 4-4V4z'/>
        </clipPath>
        <linearGradient
          id='32e6cb20'
          x1='0'
          y1='0'
          x2='1'
          y2='0'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(0 -140 345.07 0 28.4 140)'>
          <stop class='sgr is-dark-amber'
            offset='0'/>
          <stop class='sgr is-amber'
            offset='0.4'/>
        </linearGradient>
        <linearGradient
          id='6a6b2fe7'
          x1='0'
          y1='0'
          x2='1'
          y2='0'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(0 -140 345.07 0 89.2 140)'>
          <stop class='sgr is-dark-orange'
            offset='0'/>
          <stop class='sgr is-orange'
            offset='0.4'/>
        </linearGradient>
        <linearGradient
          id='d77275f6'
          x1='0'
          y1='0'
          x2='1'
          y2='0'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(0 -140 345.07 0 150 140)'>
          <stop class='sgr is-dark-purple'
            offset='0'/>
          <stop class='sgr is-purple'
            offset='0.4'/>
        </linearGradient>
        <linearGradient
          id='7bf28ccf'
          x1='0'
          y1='0'
          x2='1'
          y2='0'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(0 -140 345.07 0 210.8 140)'>
          <stop class='sgr is-dark-blue'
            offset='0'/>
          <stop class='sgr is-blue'
            offset='0.4'/>
        </linearGradient>
        <linearGradient
          id='b5a50443'
          x1='0'
          y1='0'
          x2='1'
          y2='0'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(0 -140 345.07 0 271.6 140)'>
          <stop class='sgr is-dark-green'
            offset='0'/>
          <stop class='sgr is-green'
            offset='0.4'/>
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
  import { computed } from 'vue'

  export default {
    props: {
      data: Array,
      mode: String
    },

    emits: ['select'],

    setup (props) {
      let stage = computed(() => {
        let score = props.data[0]
        if (score <= 30) return 'newcomer'
        if (score <= 45) return 'explorer'
        if (score <= 60) return 'challenger'
        if (score <= 75) return 'practitioner'
        return 'champion'
      })

      return { stage }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .maturity-stages {
    &.is-chart {
      pointer-events: none;
    }
  }

  .brk {
    opacity: 0.4;
    transition: opacity 0.2s ease-in-out;

    &.is-active {
      opacity: 1;
    }

    &.is-widget {
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .ltx {
    font-weight: map.get(variables.$font-weights, 'bold');
    fill: white;
  }

  .sop {
    transition: fill 0.2s ease-in-out;
    fill: map.get(variables.$colors, 'gray', '300');

    &.is-active {
      fill: map.get(variables.$colors, 'primary', '500');
    }
  }

  .sgr {
    @each $name in 'amber', 'orange', 'purple', 'blue', 'green' {
      &.is-#{$name} {
        stop-color: map.get(variables.$colors, $name, '500');
      }

      &.is-dark-#{$name} {
        stop-color: map.get(variables.$colors, $name, '700');
      }
    }
  }
</style>
